import { Center, Group, HStack, Icon, Separator, Spinner, Table, Tabs, VStack } from "@chakra-ui/react"

import {Tag} from "../components/ui/tag"
import { useEffect, useState } from "react";
import { StepsCompletedContent, StepsContent, StepsItem, StepsList, StepsNextTrigger, StepsPrevTrigger, StepsRoot } from "../components/ui/steps";
import { Button } from "../components/ui/button";
import { Slider } from "../components/ui/slider";
import { LuUser, LuFolder, LuCheckSquare, LuList, LuX, LuDollarSign, LuInfo, LuArrowUp, LuArrowDown, LuBookOpen } from "react-icons/lu";
import { StatLabel, StatRoot, StatValueText } from "../components/ui/stat";
import { NumberInputField, NumberInputRoot } from "../components/ui/number-input";
import { User } from "../models/user";
import { getColleges, getLoggedInUser, makeBet } from "../network/user_api";

interface CollegesPageProps {
    loggedInUser: User | null,
    modifyPoints: (amount: number) => void
}

const outcomeText = ["Accepted", "Waitlisted", "Rejected"]

export default function CollegesPage({loggedInUser, modifyPoints}: CollegesPageProps) {
      const[colleges, setColleges] = useState<any[]>([]);
      const [pointsBet, setPointsBet] = useState<any[]>([])

      const [isExpanded, setIsExpanded] = useState<boolean[]>([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
      const [betOutcome, setBetOutcome] = useState<number[]>([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);
      const [betAmount, setBetAmount] = useState<number[]>([300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300]);
      const [hasBet, setHasBet] = useState<boolean[]>([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);

      const [isProfileExpanded, setIsProfileExpanded] = useState(false);

      const [collegesLoading, setCollegesLoading] = useState(true);
      const [betLoading, setBetLoading] = useState(false);

        const toggleExpand = (index: number) => {
            let c = isExpanded;

            c = c.map((v, i) => {
                if (index == i){
                    return !v;
                } else {
                    return v;
                }   
            })

            setIsExpanded(c);
        };

        const toggleProfileExpand = () => {
            setIsProfileExpanded(!isProfileExpanded)
        };

        const editBetAmount = (amount:number, index: number) => {
            
            let c = betAmount;
            

            while (c.length <= index){
                c.push(0);
            }

            console.log(c);
            

            let u = c.map((v, i) => {
                
                if (index === i){
                    return amount;
                } else {
                    return v;
                }   
            })

            setBetAmount(u);
            
            console.log(u[index]);
        };

        async function checkUser() {
            try {
                const r = await getLoggedInUser();

                if (!r){
                    window.location.href = "/login";
                }
            } catch (error) {
                window.location.href = "/login";
            }
            
            
        }

        async function grabColleges() {
            try {
                setCollegesLoading(true)
                const coll = await getColleges();
                let c:any[] = [];

                let m:any[] = [];

                setColleges(coll);


                let r: any[] = [];
                
                for (let i = 0; i < coll.length; i++) {
                    editBetAmount(300, i);
                    let b = false;
                    
                    for (let z = 0; z < coll[i].betUsers.length; z++) {
                        
                        
                        if ((coll[i].betUsers[z].username as string) === (loggedInUser?.username as string)){
                            console.log(coll[i].betUsers[z].username);
                            
                            b = true;
                        }
                    }

                    console.log(b);
                    

                    r.push(b);

                    
                }

                console.log(r);
                

                setHasBet(r);
                setPointsBet(c);
                setBetAmount(m);
                
                
                
            } catch (error:any) {
               
            } finally {
                setCollegesLoading(false)
            }
        }

        useEffect(() => {
            
            setBetAmount(betAmount.fill(300, 0, 25))
            setBetOutcome(betOutcome.fill(300, 0, 25))
            setHasBet(hasBet.fill(false, 0, 25))
            checkUser();
            grabColleges();
            
        }, [])
        

    return (
        <div style={{justifyContent:'center', margin:'auto'}}>
            <div className={`CollegeIsland ${(isProfileExpanded as boolean) ? 'expanded' : ''}`}>
            <HStack onClick={() => {toggleProfileExpand();}}>
                <div style={{width:'700px'}} >
                    <h1 style={{color:'white', textAlign:'start', paddingTop:'17px', paddingLeft:'15px', fontWeight:600}}>My Academic Profile</h1>
                    <h2 style={{textAlign:'start', paddingLeft:'15px'}}>Inform your bets</h2>
                    
                    
                </div>
            
                </HStack>

                {isProfileExpanded && <div style={{marginLeft:'20px', marginTop:'30px'}}>
                    <h1 style={{color:'#F3F2E6'}}>Numbers</h1>
                    <p><b>GPA:</b> 3.78 UW</p>
                    <p><b>SAT:</b> 1340</p>
                    <p><b>Reported AP Scores:</b></p>
                    <p>5 - AP Computer Science A</p>
                    <p>4 - AP World</p>
                    <p>4 - AP Lang</p>
                    <Separator/>
                    <h1 style={{color:'#F3F2E6', marginTop:'15px'}}>Extra Curriculars</h1>
                    <div style={{display:'flex', marginTop:'25px', flexWrap:'wrap'}}>
                        <p><b>Software Engineering: </b> Developed numerous pieces of software including a practice website for my academic decathlon team which gave individual and group insights, multiple game jams and small video game projects using Unity and C#, Discord bots using JavaScript, a personal portfolio website, and more stuff. Currently working to publish a game on Steam. (check out my website haydenkarp.com for more)</p>
                        <p><b>Academic Decathlon: </b> Served for 3 years on the competitive team at local, regional, and state competitions, earned numerous awards. Also served as an officer two years of my membership and helped support the program through my pursuit of computer science</p>
                        <p><b>Computer Science Club: </b> Helped organize general club operations including club-wide projecst and workshops to improve members' collaborative abilities as well as domain specific knowledge like React and Web Development</p>
                        <p><b>Worked At Code Ninjas: </b> Taught kids from ages 4 - 14 how to code using blocks, JavaScript, and C#. Taught various computer science concepts and language specific design philosophies</p>
                    
                        <p><b>National Honor Society: </b> Member 11th and 12th grade</p>
                    
                        </div>
                </div>}
            </div>

            {collegesLoading && <Center><Spinner style={{marginTop:'60px'}}/></Center>}
            {!collegesLoading && <> {colleges.map((v, i) => {return (<div className={`CollegeIsland ${(isExpanded[i] as boolean) ? 'expanded' : ''}`}>
                
                <HStack onClick={() => {toggleExpand(i);}}>
                <div style={{width:'700px'}} >
                    <h1 style={{color:'white', textAlign:'start', paddingTop:'17px', paddingLeft:'15px', fontWeight:600}}>{v.title}</h1>
                    <h2 style={{textAlign:'start', paddingLeft:'15px'}}>{v.location}</h2>
                    
                </div>
                
                    <StatRoot zIndex="0" maxW="240px" borderWidth="1px" p="4" rounded="md" style={{marginLeft:'30%', minHeight:'100px',  top:'25px', borderColor:'#F3F2E6'}}>
                        <HStack justify="space-between" style={{paddingBottom:'10px'}}>
                            <StatLabel style={{fontFamily:'Laro', fontSize:24, color:'#F3F2E6'}}>Total Points Bet</StatLabel>
                        </HStack>
                        <StatValueText style={{fontFamily:'Laro', fontSize:48, color:'#F3F2E6'}}>{v.totalPointsBet.toString()}</StatValueText>
                    </StatRoot>
                </HStack>
                
                <div style={{display:'flex', flexWrap:'nowrap', marginLeft:'10px'}}>
                    {
                        v.tags.map((u:any, i:number) => {
                            return (<div style={{backgroundColor:v.tagColor, width:'fit-content', height:'fit-content', textAlign:'center', borderRadius:'20px', margin:'8px', marginTop:'15px'}}>
                                <p style={{padding:'5px', margin:'auto', fontWeight:600}}>{u}</p>
                            </div>)
                        })
                    }
                </div>
                

                {
                    isExpanded[i] &&
                    <div>
                        <HStack style={{width:'100%'}}>
                        <div style={{padding:'20px', paddingBottom:'30px', paddingLeft:'10px', width:'100%', display:'flex'}}>
                            <div style={{display:'flex', padding:'15px'}}>
                                <p><span style={{fontWeight:600}}>Acceptance Rate:</span> {v.acceptanceRate}</p>
                            </div>

                            <div style={{display:'flex', padding:'15px'}}>
                                <p><span style={{fontWeight:600}}>Application Type:</span> {v.applicationType}</p>
                            </div>

                            <div style={{display:'flex', padding:'15px'}}>
                                <p><span style={{fontWeight:600}}>Application Status:</span> {v.applicationStatus}</p>
                            </div>

                            <div style={{display:'flex', padding:'15px'}}>
                                <p><span style={{fontWeight:600}}>National Rank:</span> {v.nationalRank}</p>
                            </div>

                            <div style={{display:'flex', padding:'15px'}}>
                                <p><span style={{fontWeight:600}}>Essay Count:</span> {v.essayCount}</p>
                            </div>
                        </div>

                        
                        </HStack>

                        <Separator style={{width:'90%', justifyContent:'center', margin:'auto', marginBottom:'50px'}} />
                    

                        <div style={{justifyContent:'center', margin:'auto'}}>
                            {(!hasBet[i]) && <Center style={{borderColor:(!loggedInUser || hasBet[i] || v.betUsers.includes(loggedInUser) ? "#C2C1BA" : "#F3F2E6"), borderRadius:'5px', borderWidth:'2px', width:'fit-content', paddingRight:'10px', paddingLeft:'10px', justifyContent:'center', margin:'auto', paddingTop:'20px', paddingBottom:'20px'}}>
                                <VStack>
                                    <HStack>
                                        <Tabs.Root style={{fontFamily:'Laro'}} colorPalette='accent' defaultValue={"waitlisted"} variant={"enclosed"}>
                                            <Tabs.List style={{backgroundColor:(!loggedInUser || hasBet[i] ? "#C2C1BA" : "#F3F2E6")}}>
                                            <Tabs.Trigger onClick={() => {
                                                let c = betOutcome;
                                                c[i] = 0;

                                                setBetOutcome(c);
                                                editBetAmount(betAmount[i], i);
                                            }} disabled={!loggedInUser || hasBet[i]} value="accepted">
                                            <LuCheckSquare />
                                                Accepted
                                            </Tabs.Trigger>
                                            <Tabs.Trigger onClick={() => {
                                                let c = betOutcome;
                                                c[i] = 1;

                                                setBetOutcome(c);
                                                editBetAmount(betAmount[i], i);
                                            }} disabled={!loggedInUser || hasBet[i]} value="waitlisted">
                                                <LuList/>
                                                Waitlisted
                                            </Tabs.Trigger>
                                            <Tabs.Trigger onClick={() => {
                                                let c = betOutcome;
                                                c[i] = 2;

                                                setBetOutcome(c);
                                                editBetAmount(betAmount[i], i);
                                            }} disabled={!loggedInUser || hasBet[i]} value="rejected">
                                                <LuX/>
                                                Rejected
                                            </Tabs.Trigger>
                                            </Tabs.List>
                                        </Tabs.Root>

                                        <NumberInputRoot size={"lg"} disabled={!loggedInUser || hasBet[i]} colorPalette={'accent'} style={{paddingLeft:'20px', fontFamily:'Laro'}} onValueChange={(e) => {
                                                editBetAmount(e.valueAsNumber, i)
                                            }} width="200px" defaultValue="300" min={1} max={loggedInUser ? loggedInUser.points : 0}>
                                            <NumberInputField value={betAmount[i] as unknown as string} style={{backgroundColor:'#F3F2E6', color:'#494846'}} />
                                        </NumberInputRoot>
                                    </HStack>
                                    <VStack>
                                        <p style={{paddingTop:'5px'}}><b>Possible Winnings ({v.odds[betOutcome[i]]} : 1):</b> {((v.odds[betOutcome[i]] as number) * betAmount[i]) ? ((v.odds[betOutcome[i]] as number) * betAmount[i]) + " Points" : "N/A"}</p>
                                        <Button loading={betLoading} disabled={!loggedInUser || hasBet[i] || isNaN(((v.odds[betOutcome[i]] as number) * betAmount[i])) || betAmount[i] <= 0} style={{borderRadius:'5px', backgroundColor:'#F3F2E6', color:'#494846', marginTop:'1px', fontFamily:'Laro'}} size="md" onClick={async () => {
                                            setBetLoading(true);

                                            if (loggedInUser && !hasBet[i]){
                                                try {
                                                    await makeBet({
                                                        amount: betAmount[i],
                                                        collegeName: v.title,
                                                        expectedOutcome: betOutcome[i]
                                                    });

                                                    modifyPoints(betAmount[i]);
                                                    v.betUsers.push(loggedInUser);
                                                    v.betOutcomes.push(betOutcome[i]);
                                                    v.betAmounts.push(betAmount[i]);
                                                    v.totalPointsBet += betAmount[i];

                                                    const c = hasBet;

                                                    c[i] = true;

                                                    setHasBet(hasBet)
                                                } catch (error) {
                                                    
                                                }
                                            }
                                            
                                            
                                            setBetLoading(false);
                                        }} >Place Bet</Button>
                                        
                                    </VStack>
                                    
                                    <HStack>
                                        <Icon>
                                            <LuInfo/>
                                        </Icon>
                                        <p style={{marginTop:'17px'}}>Warning: You can only place one bet per college, you cannot undo bets</p>
                                    </HStack>
                                </VStack>
                                
                            </Center>}
                            <Table.Root size={"lg"} borderRadius={20} style={{width:'70%', margin:'auto', marginTop:'40px', borderRadius:'20px', height:'90px', backgroundColor:'#494846'}}>
                                <Table.Header>
                                    <Table.Row style={{backgroundColor:'#494846', color:'white', fontFamily:'Lora'}}>
                                    <Table.ColumnHeader style={{color:'white', fontWeight:600}}>Name</Table.ColumnHeader>
                                    <Table.ColumnHeader style={{color:'white', fontWeight:600}}>Bet</Table.ColumnHeader>
                                    <Table.ColumnHeader style={{color:'white', fontWeight:600}}>Expected Decision</Table.ColumnHeader>
                                    <Table.ColumnHeader style={{color:'white', fontWeight:600}} textAlign="end">Possible Winnings</Table.ColumnHeader>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {v.betUsers.map((user:User, index:number) => (
                                        <Table.Row style={{backgroundColor:'#494846', color:'white', fontFamily:'Lora'}} key={user._id}>
                                            <Table.Cell>{user.username}</Table.Cell>
                                            <Table.Cell>{v.betAmounts[index]}</Table.Cell>
                                            <Table.Cell>{outcomeText[v.betOutcomes[index]]}</Table.Cell>
                                            <Table.Cell textAlign="end">{(v.odds[v.betOutcomes[index]]) * v.betAmounts[index]}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                        </Table.Root>
                        
                        </div>
                    </div>
                }
                
            </div>)})}</>}

            


        </div>
    )
}
